<template>
	<v-card flat>
        <v-card-text>
            <v-simple-table>
                <thead>
                    <tr>
                        <th>Job Function</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="jf in jobFunctions" :key="jf.id">
                        <td>{{ jf.name }}</td>
                        <td>{{ count(jf.id) }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "CountResultsByJobFunction",
	computed: {
		jobFunctions() {
			return this.$store.getters["jobFunctions/options"];
		},
		responses() {
			return this.$store.state.staffSurveyResponses.data;
		},
	},
	methods: {
		count(j) {
			return Object.values(this.responses).filter(
				(a) => a["jobFunction"] == j
			).length;
		},
	},
};
</script>
