<template>
	<p class="text-right">
		<export
			:columns="columns"
			:rows="rows"
            name="staff-survey-data"
			text="Export full dataset"
		></export>
	</p>
</template>

<script>
import Export from "@c/downloads/Export";

export default {
	name: "ExportData",
	computed: {
		columns() {
			return [
                { text: "ID", value: "id"} ,
                { text: "Question", value: "question"} ,
                { text: "Job Function", value: "jobFunction"} ,
                { text: "Answer", value: "answer" },
                { text: "Score", value: "score"} 
            ]; 
		},
		jobFunctions() {
			return this.$store.state.categoryOptions.data;
		},
		rows() {
			const self = this;
			return this.allResponses.map((r) => {
                let row = {
                    id: r.id,
                    answer: r.processed_answer, 
                    score: r.score
                }
				let job = self.jobFunctions[r["jobFunction"]];
				if (job) {
					row["jobFunction"] = job.name;
				} else {
					row["jobFunction"] = "";
				}
				let questionTexts = self.$store.getters["questions/getQuestionTexts"]( r.question ) 
                row.question = questionTexts.string
            
				return row;
			});
		},
		allResponses() {
			return this.$store.getters["staffSurveyResponses/responses"];
		},
	},
	components: {
		Export,
	},
};
</script>
