<template>
	<v-sheet>
		<h2 class="mb-4">Staff Survey</h2>
		<div v-html="$t('staff_survey.before_copy')"></div>
		<staff-survey-link></staff-survey-link>
		<div v-html="$t('staff_survey.after_copy')"></div>
		<render-content id="staffsurveyresponses"></render-content>
		<count-results-by-job-function></count-results-by-job-function>
		<staff-survey-viz :id="staffSurveyId" v-if="section"></staff-survey-viz>
		<response-table :id="staffSurveyId" v-if="section"></response-table>
		<export-data :id="staffSurveyId" v-if="section"></export-data>
	</v-sheet>
</template>

<script>
import StaffSurveyLink from "@c/staff-survey/Link.vue";
import CountResultsByJobFunction from "@c/staff-survey/CountResultsByJobFunction.vue";
import ResponseTable from "@c/staff-survey/ResponseTable.vue";
import StaffSurveyViz from "@c/staff-survey/StaffSurveyViz.vue";
import ExportData from "@c/staff-survey/ExportData.vue";
import RenderContent from "@c/ui/RenderContent";
export default {
	name: "StaffSurveyDashboard",
	data: () => {
		return {
			ready: false,
		};
	},
	computed: {
		organisation() {
			return this.$store.state.organisation.data.id;
		},
		staffSurveyId() {
			return this.$store.state.config.data["staff_survey"];
		},
		section() {
			return this.$store.state.sections.data[this.staffSurveyId];
		},
	},
	components: {
		ResponseTable,
		StaffSurveyLink,
		CountResultsByJobFunction,
		ExportData,
		StaffSurveyViz,
		RenderContent,
	},
	watch: {
		organisation: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("staffSurveyResponses/openDBChannel", {
						clauses: {
							where: [["organisation", "==", value]],
						},
					});
				}
			},
		},
		staffSurveyId: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("sections/fetchById", value);
				}
			},
		},
	},
	created() {
		this.$store.dispatch("jobFunctions/openDBChannel");
		// this.$store.dispatch("indicators/openDBChannel");
		// this.$store.dispatch("questions/openDBChannel");
		// this.$store.dispatch("questionTemplates/openDBChannel");
	},
};
</script>
