<template>
	<v-sheet>
		<v-card flat>
			<v-card-text>
				<v-select v-model="question" :items="questions" label="Select Question"></v-select>
				<Chart :loading="false" id="chart" type="BarChart" v-bind="chartData" :options="options"></Chart>
			</v-card-text>
		</v-card>
	</v-sheet>
</template>

<script>
import Chart from "@c/results/analysis/Chart";

export default {
	name: "StaffSurveyViz",
	props: {
		id: { type: String }, // the ID of the staff survey
	},
	components: {
		Chart,
	},
	data: () => {
		return {
			question: false,
			options: {
				responsive: true,
				scales: {
					xAxes: [
						{
							stacked: true,
						},
					],
					yAxes: [
						{
							stacked: true,
						},
					],
				},
			},
		};
	},
	computed: {
		responses() {
			return this.$store.getters["staffSurveyResponses/responses"];
		},
		section() {
			return this.$store.state.sections.data[this.id];
		},
		questions() {
			const self = this;
			let questions = [];
			if (!self.section) {
				return questions;
			} else {
				questions = self.section.questions.map((q) => {
					let questionTexts = self.$store.getters["questions/getQuestionTexts"](q, true);
					return { value: q, text: questionTexts.string };
				});
			}
			return questions;
		},
		jobFunctions() {
			return this.$store.getters["jobFunctions/options"];
		},
		// labels() {
		// 	return Object.fromEntries(this.jobFunctions.map((j) => [j.id, j.name]));
		// },
		questionResponses() {
			let q = this.question;
			if (!q) {
				return [];
			}
			return this.responses.filter((r) => r.question == q);
		},
		empty() {
			const self = this;
			if (!self.question) {
				return {};
			}
			let answerOptions = self.$store.getters["questions/getAnswerOptions"](self.question);
			return Object.fromEntries(Object.keys(answerOptions).map((a) => [answerOptions[a].text, {}]));
		},
		results() {
			const self = this;
			if (!self.question) {
				return false;
			}
			let buckets = { ...self.empty };
			let labels = {};
			self.jobFunctions.forEach((job) => {
				labels[job.id] = job.name;
				Object.keys(buckets).forEach((a) => {
					buckets[a][job.id] = 0;
				});
			});

			self.questionResponses.forEach((r) => {
				let job = r["jobFunction"];
				if (r.answer) {
					buckets[r.processed_answer][job]++;
				}
			});
			return { buckets, labels };
		},
		chartData() {
			let output = {
				labels: [''],
				data: [],
			};
			let results = this.results;
			if (!results) {
				return output;
			}
			output.labels = Object.values(results.labels);

			let colors = ["red", "orange", "yellow", "lightgreen", "darkgreen", "grey", "darkgrey"];
			Object.keys(results.buckets).forEach((a, i) => {
				output.data.push({
					label: a,
					data: Object.values(results.buckets[a]),
					backgroundColor: colors[i],
				});
			});
			return output;
		},
	},
};
</script>
