<template>
	<v-card flat class="mt-4">
		<v-card-text>
			<v-data-table :headers="headers" :items="items">
				<template v-slot:footer.prepend>
					<export
						:columns="headers"
						:rows="items"
						name="staff-survey-overview"
						icon
					></export>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
import Export from "@c/downloads/Export";
export default {
	name: "ResponseTable",
	props: {
		id: { type: String }, // the ID of the staff survey
	},
	components: {
		Export,
	},
	computed: {
		categoryOptions() {
			return this.$store.state.categoryOptions.data;
		},
		jobFunctions() {
			return this.$store.getters["jobFunctions/options"];
		},
		responses() {
			return this.$store.state.staffSurveyResponses.data;
		},
		section() {
			return this.$store.state.sections.data[this.id];
		},
		questions() {
			const self = this;
			if (!self.section) {
				return [];
			} else {
				return self.section.questions.map((a) => {
					return self.$store.state.questions.data[a];
				});
			}
		},
		questionTemplates() {
			return this.$store.state.questionTemplates.data;
		},
		indicators() {
			return this.$store.state.indicators.data;
		},
		headers() {
			var self = this;
			var headers = [{ value: "question", text: "Question" }];
			self.jobFunctions.forEach((j) => {
				headers.push({ value: j.id, text: j.name });
			});
			return headers;
		},

		allResponses() {
			return this.$store.getters["staffSurveyResponses/responses"];
		},
		responsesByQuestion() {
			const self = this;
			return Object.fromEntries(
				self.section.questions.map((q) => {
					return [q, self.allResponses.filter((r) => r.question == q)];
				})
			);
		},
		averageAnswerByQuestion() {
			const self = this;
			let questions = self.section.questions.filter((q) => {
				let answerOptions = self.$store.getters[
					"questions/getAnswerOptions"
				](q);
				return answerOptions;
			});
			return Object.fromEntries(
				questions.map((q) => {
					let averages = {};
					let answerOptions = self.$store.getters[
						"questions/getAnswerOptions"
					](q);
					let responses = self.responsesByQuestion[q];
					self.jobFunctions.forEach((job) => {
						let jobResponses = responses.filter(
							(a) => a["jobFunction"] == job.id
						);
						let average = self.mwsurveyutils.averageResponse(
							jobResponses,
							Object.values(answerOptions)
						);
						averages[job.id] = average;
					});
					return [q, averages];
				})
			);
		},
		items() {
			const self = this;
			let responses = self.averageAnswerByQuestion;
			return Object.keys(responses).map((question) => {
				let r = { ...responses[question] };
				r.question_id = question;
				let questionTexts = self.$store.getters["questions/getQuestionTexts"]( question ) 
                r.question = questionTexts.string;
				return r;
			});
		},
	},
	methods: {},
};
</script>
